<template>
  <v-app id="login" class="primary">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="elevation-1 pa-3">
              <div class="layout column align-center">
                <img src="./../assets/images/logo.png" class="logo" alt="InsightzClub">
              </div>

              <div v-if="loginWithQR">
                <center>
                  <div class="layout column align-center" 
                    v-on:click="webSocketSeviceFn"
                    v-if="qrExpired"
                  >
                    <img src="./../assets/images/refresh.png" class="refresh" alt="refresh">
                  </div>
                  <qrcode-vue v-if="qrValue"
                    :value="qrValue" 
                    :size="200" 
                    level="H" 
                  />

                  <h2 class="font-weight-regular pt-4">{{ $t("scan_to_login_insightzclub" ) }}</h2>

                  <p class="ma-0 pt-8 pb-0">{{ $t("scan_to_login_insightzclub_description" ) }}</p>
                  <p class="pa-0 font-weight-bold pr-1">{{ $t("unable_to_scan_qr") }}<a @click="loginWithQR = false">{{ $t("login_with_otp") }}</a></p>
                </center>
              </div>
              <div v-else>
                <v-card-text>
                  <v-form>
                    <v-text-field 
                      append-icon="person" 
                      name="E-mail" 
                      label="E-mail"
                      type="text" 
                      v-model="loginDto.email" 
                      required
                      v-validate="'required|email'"
                      data-vv-name="email"
                      :error-messages="errors.collect('email')"
                      v-on:click="resetError()"
                    />
                    <v-text-field 
                      append-icon="lock" 
                      name="OTP" 
                      label="OTP" 
                      type="text" 
                      v-model="loginDto.otp" 
                      required  
                      v-validate="'required|min:6'"
                      :error-messages="errors.collect('OTP')"
                      v-on:click="resetError()"
                      v-on:keyup.enter="login"
                      @keyup.enter="login"
                    />
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" @click="loginWithOTP">{{ $t("login") }}</v-btn>
                  <v-btn color="primary" text @click="loginWithQR = true">{{ $t('login_with_qr') }}</v-btn>
                </v-card-actions>
              </div>

              <div class="layout column align-center">
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-translate</v-icon>
                      {{ $t("language") }}
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="(l, i) in languages" :key="i" @click="setLanguage(l)">
                      <v-list-item-title>{{ l.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

import { DataService } from '../services/DataService'

const dataService = new DataService()

export default {
  components: {
    QrcodeVue 
  },
  data() {
    return {
      qrExpiredFn: null,
      qrExpired: false,
      qrValue: false,
      loginWithQR: true,
      webSocketService: null,
      languages: [],
      errorMessage: "",
      failed: false,
      loginDto: {}
    }
  },
  watch: {
    loginWithQR: function(val) {
      if (val) {
        this.webSocketSeviceFn()
      } else {
        this.setQRExpired()
      }
    }
  },
  created() {
    this.$cookies.set('platform', 'web')
  },
  mounted() {
    dataService.getConfig("languageConfig").then(data => {
      this.languages = data
    })

    this.webSocketSeviceFn()
  },
  methods : {
    setLanguage(selected) {
      this._i18n.locale = selected.key
      this.$store.state.language.selected = selected.key
    },
    webSocketSeviceFn() {
      this.$setLoader()
      this.qrExpired = false
      this.webSocketService = new WebSocket(process.env.VUE_APP_SOCKET_URL)
      
      this.webSocketService.addEventListener("message", res => {
        let data = res.data ? JSON.parse(res.data) : {}
        
        if (data.uniqueId) {
          this.qrValue = data.uniqueId
          this.$clearLoader()

          this.qrExpiredFn = setTimeout(this.setQRExpired, 180000)
        }

        if (data.user && data.user.token && data.user._id && data.user.email) {
          this.$setLoader()
          
          this.$store.state.login = true

          this.$cookies.set('token', data.user.token)
          this.$cookies.set('userId', data.user._id)
          this.$cookies.set('email', data.user.email)
        
          if (undefined != data.card && data.card != null) {
            this.$router.push({ name: 'ShowPassiveData', params: { card: data.card } })
          } else if (undefined != data.redirectTo && data.redirectTo != null) {
            this.$router.push({ name: data.redirectTo })
          } else {
            this.$router.push({ name: 'Root' })
          } 

        }
      })
    },
    setQRExpired() {
      this.qrExpired = true
      this.webSocketService.close()
      clearTimeout(this.qrExpiredFn)
    },
    resetError() {
      this.failed ? this.failed = false : "";
    },
    loginWithOTP() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          this.$setLoader()
          
          dataService.getOTPLoginUser(this.loginDto).then(res => {
            this.$cookies.set('token', res.user.token)
            this.$cookies.set('userId', res.user._id)
            this.$cookies.set('email', res.user.email)
            
            this.$router.push({ name: 'Root' })
          })
        }
      });
    }
  }
};
</script>

<style scoped lang="css">
.logo {
  height: auto;
  width: 280px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.refresh {
  height: auto;
  width: 200px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
}

#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
</style>
